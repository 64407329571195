import { $ } from '../jquery-shim';

import logger from '../../../shared/logger';
import { clientLanguage } from '../helpers/client-language';
import { clientIdentity } from '../helpers/client-identity';
import { deviceFeatures } from '../helpers/device-features';
import { util } from '../helpers/util';
import { config } from '../config-shim';
import { domH } from '../helpers/dom-helper';
import { isConnected } from '../helpers/ajax-connection2';
import { StudentSession } from '../models/student-session';

export let videoHelpers = {
  init,
  prepareVideo,
  prepareStudentVideo,
  stopVideo,
}

const SIZE_RE = /-([0-9]{3})/;

// GLOBAL VARIABLES

$(document).on('webkitfullscreenchange', onFullscreen)
$(document).on('mozfullscreenchange', onFullscreen)
$(document).on('MSFullscreenChange', onFullscreen)

let videoJustStarted = false;
let lastRestartAttempt = 0; // prevent newer versions of Safari from getting stuck in a play -> access denied -> pause loop
let activityTemplateFn;
let overviewTemplateFn;

// EXPORTED FUNCTIONS

function init() {
  activityTemplateFn = util.prepareTemplate($('.activityVideoTemplate').text());
  overviewTemplateFn = util.prepareTemplate($('.overviewVideoTemplate').text());
}

function prepareVideo(container: JQuery, name: string): void {
  logger.debug("Adding video %s", name);
  $('.video.player', container).show();
  injectVideo(container, name);
}

function prepareStudentVideo(container: JQuery, videoNumber: string, session: StudentSession): void {
  $('.player', container).hide();
  if (videoNumber) {
    logger.debug("Adding video %s", videoNumber);
    $('.player', container).show();
    let src = 'activity-' + videoNumber;
    bindTranscriptEvents(container, src, videoNumber, session);
    injectStudentVideo(container, src, videoNumber, session);
  } else {
    $('.still', container).show();
  }
}

function stopVideo(container: JQuery, unbind: boolean): void {
  let $videoElt = $('.player.video', container);
  if ($videoElt.length > 0) {
    videoMethod($('video', $videoElt)[0], 'pause');
    $videoElt.empty();
  }
  $('video source', container).off('error');
  if (unbind) {
    $('.displayVideo', container).off('tap');
    unbindStudentEvents(container);
    unbindTranscriptEvents(container);
  }
}

// HELPER FUNCTIONS

function bindTranscriptEvents(container: JQuery, src: string, videoNumber: string, session?: StudentSession): void {
  var $transcripts = $('.transcripts', container);
  $('.transcript .close', container).on('tap', logger.wrapEventHandler(function() {
    $('.speech_bubble', container).show();
    $transcripts.hide();
  }));
  $('.displayVideo', container).on('tap', logger.wrapEventHandler(function() {
    injectStudentVideo(container, src, videoNumber, session);
  }));
}

function bindErrorHandler(container: JQuery, videoNumber?: string, session?: StudentSession): void {
  var $video = $('video', container);
  var $source = $('source', $video);
  $source.last().on('error', logger.wrapEventHandler(function(e) {
    $('.player', container).hide();
    $('.videoError', container).show();
    if (session) {
      // student activity
      showTranscript(container, videoNumber);
    } else {
      showVideoError(container);
    }
    let videoElement = e.target.parentElement;
    if (videoElement.networkState === 3) {
      if (session && !session.videoUnavailableShown) {
        session.unavailableShown();
        domH.openPopup('#videoErrorPopup');
      }
    }
    let webm = 'unknown', mp4 = 'unknown', goodSupport = false;
    if (videoElement.canPlayType) {
      // mp4 codec type is: h264 Main (avc1.4D40), Level 3.1 (multiply by 10, convert to hex = 1F)
      // so spec is avc1.4D401F -- see https://wiki.whatwg.org/wiki/Video_type_parameters#Video_Codecs_3
      mp4 = videoElement.canPlayType('video/mp4; codecs=avc1.4D401F, mp4a.40.2');
      webm = videoElement.canPlayType('video/webm; codecs=vp8, opus');
      goodSupport = mp4 === 'probably' || webm === 'probably';
    }
    let message = goodSupport ? "Error playing back video." : "Codec error for video.";
    let report = {
      networkState: videoElement.networkState,
      readyState: videoElement.readyState,
      src: videoElement.currentSrc,
      userAgent: navigator.userAgent,
      locale: clientLanguage.currentLocale(),
      deviceCode: clientIdentity.deviceCode(),
      canPlayMp4: mp4,
      canPlayWebM: webm,
      once: true
    };
    logger.warnObject(report, message);
  }));

}

function bindStudentEvents(container: JQuery): void {
  // NOTE: Android devices depend on the video element being clickable, which
  // depends on the browser respecting the "pointer-events: none" attribute set in '.poster' class CSS.

  // NOTE: iOS will not autoplay videos, so this gets a little complicated:
  // 1) When we load the container, the poster is shown and has greatest z-index
  // 2) Once the video starts playing, we dismiss the poster and loading images, and can see it.  The issue is that
  //    this won't happen on an iOS device.
  // 3) If the div is tapped (because there's no autoplay), we wait a moment, then dismiss the poster,
  //    revealing the loading gif if necessary.  We then proceed to 2).
  // We will not currently ever display the loading gif if the poster isn't tapped, which is suboptimal but acceptable
  // for the time being in SRN's opinion.
  var $video = $('video', container);
  $('.videoLoading', container).show();
  $video.on('tap', logger.wrapEventHandler(function(e) {
    e.preventDefault();
    if (!this.controls) {
      setTimeout(function() { $('.poster', container).css({ 'z-index': '-1' }); }, 250);
      if (this.paused) { videoMethod(this, 'play'); }
      else { videoMethod(this, 'pause'); }
    }
  }));
  $video.on('canplay', logger.wrapEventHandler(function(_e) {
    hideVideoError(container);
    $('.videoLoading', container).hide();
  }));
  $video.on('play', logger.wrapEventHandler(function(_e) {
    videoJustStarted = true;
  }));
  $video.on('timeupdate', logger.wrapEventHandler(function(e) {
    if (videoJustStarted && e.target.currentTime > 0) {
      logger.debug("Hiding poster on timeupdate at %s", e.target.currentTime);
      videoJustStarted = false;
      $('.poster', container).css({ 'z-index': '-1' });
      $('.videoLoading', container).hide();
    }
  }));
  $video.on('pause', logger.wrapEventHandler(function(e) {
    logger.debug("Video paused");
    if (e.target.currentTime < 0.5 && (Date.now() - lastRestartAttempt) > 100) {
      // assume we weren't paused from user input, probably because of a ui transition sound in iOS 10
      lastRestartAttempt = Date.now();
      videoMethod(e.target, 'play');
    }
    if (this.ended) { $('.poster', container).css({ 'z-index': '20' }); }
  }));
}

function configureVideo(container: JQuery, name: string, videoNumber?: string, session?: StudentSession): void {
  let $elt = $('.video.player', container);
  let src = videoSrc(name);
  let html;
  $('.videoError', container).hide();
  if (session) {
    html = activityTemplateFn({ source: src });
  } else {
    let size = determineSize(container);
    html = overviewTemplateFn({ source: src, size: size });
  }
  $elt.html(html);
  bindErrorHandler(container, videoNumber, session);
  if (session) { bindStudentEvents(container); }
  if (config.cordova.enabled && window.device.platform == 'iOS' && deviceFeatures.versionIsAtLeast('8.0')) {
    // element is not redrawn in iOS 8 after HTML insertion without this line; showing div at this point in 7.0 results in a black flash.
    $elt.show();
  }
  $('.poster', container).css({ 'z-index': '20' });
  $('.videoLoading', container).hide();
}

function resizeVideo(videoElt: HTMLVideoElement): void {
  let currentTime = videoElt.currentTime;
  let size = determineSize($(videoElt));
  let sources = videoElt.getElementsByTagName('source');
  let resized = false;
  for (let i = 0; i < sources.length; i++) {
    let source = sources[i];
    let matches = source.src.match(SIZE_RE);
    let prevSize = parseInt(matches && matches[1]);
    if (prevSize !== size) {
      source.src = source.src.replace(SIZE_RE, '-' + size.toString());
      resized = true;
    }
  }
  if (resized) {
    videoElt.load();
    videoElt.currentTime = currentTime;
    videoElt.play();
  }
}

function injectStudentVideo(container: JQuery, src: string, videoNumber: string, session?: StudentSession): void {
  if (!isConnected()) {
    showTranscript(container, videoNumber);
    if (session && !session.videoUnavailableShown) {
      session.unavailableShown();
      domH.openPopup('#videoUnavailablePopup');
    }
  } else { injectVideo(container, src, videoNumber, session); }
}

function determineSize($elt: JQuery): number {
  let containerHeight = $elt.height();
  let maximumSize = containerHeight * util.devicePixelRatio();
  let availableSizes = [720, 436, 360];
  let size;
  for (var i = 0; i < availableSizes.length; i++) {
    let possibleSize = availableSizes[i];
    if (maximumSize > possibleSize) {
      let prevIndex = i === 0 ? 0 : i-1;
      size = availableSizes[prevIndex];
      break;
    }
    if (i === availableSizes.length - 1) { size = possibleSize; break; }
  }
  return size;
}

function injectVideo(container: JQuery, name: string, videoNumber?: string, session?: StudentSession): void {
  configureVideo(container, name, videoNumber, session);
  let videoElt = $('video', container)[0];
  let success = videoMethod(videoElt, 'load') && videoMethod(videoElt, 'play');
  if (success) { $('.displayVideo, .transcripts', container).hide(); }
  else { showTranscript(container, videoNumber); }
}

function onFullscreen() {
  let fullscreenElement = document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
  if (fullscreenElement && fullscreenElement.nodeName == 'VIDEO') {
    resizeVideo(fullscreenElement as HTMLVideoElement);
  } else {
    setTimeout(function(){
      // delay because it takes a moment to re-flow styles and thus resize video element if we're in phone view.
      let $video = $('video');
      if ($video.length === 0) { return; }
      if ($video.length > 1) { logger.warn("More than one video element when switching out of fullscreen mode.") }
      resizeVideo($video[0]);
    }, 500)
  }
}

function showTranscript(container: JQuery, video: string): void {
  var $transcripts = $('.transcripts', container);
  $('h4', $transcripts).hide();
  var $transcript = $('.transcript', $transcripts);
  var $closeBox = $('.close', $transcript).detach();
  $transcript.empty();
  $transcript.append($closeBox);
  $transcript.append($('#transcripts-' + clientLanguage.currentLocale() + ' .transcript' + video).html());
  $('.displayVideo, .still', container).show();
  $('.speech_bubble', container).hide();
  $transcripts.show();
}

function showVideoError(container: JQuery): void {
  $('.videoError', container).css({ 'z-index': '21' })
}

function hideVideoError(container: JQuery): void {
  $('.videoError', container).css({ 'z-index': '-10' })
}

function unbindTranscriptEvents(container: JQuery): void {
  $('.displayVideo', container).off('tap');
  $('.transcripts .close', container).off('tap');
}

function unbindStudentEvents(container: JQuery): void {
  $('video', container).off('tap');
  $('video', container).off('play');
  $('video', container).off('timeupdate');
  $('video', container).off('pause');
}

function videoSrc(video: string): string {
  let rval = config.protocol + '//' + config.cdn0 + '/videos/tablet/' + clientLanguage.currentLocale() + '/' + video;
  return rval;
}

function videoMethod(videoElt: HTMLVideoElement, method) {
  // We're seeing infrequent "Unexpected call to method or property access."
  // errors when trying to access html5 video
  // elements on some IE browsers. Suspect it has to do with unsupported media types
  // rendering a DOM node that doesn't have the media methods (similar to this videoJS
  // issue: https://github.com/videojs/video.js/issues/984). Catch errors, send back object
  // and assign all to same warning message.
  if (!videoElt) { return true; }
  try {
    videoElt[method]()
  } catch (err) {
    if (err.name === 'AbortError' || err.name === 'NotAllowedError') {
      // ignore Safari aborting non user-requested playback, because we can't get around it.
      return true;
    }
    let report = {
      nodeName: videoElt.nodeName,
      method: method,
      parentClasses: videoElt.parentElement.getAttribute('class'),
      message: err.message,
      stack: err.stack,
      once: true
    };
    logger.errorObject(report, "Error accessing method on video element");
    return false;
  }
  return true;
}
