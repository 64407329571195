// Include moment locale data so that calendar months can be rendered.

// LATER: Currently we use same JS for all languages. If we could include locale in HTML header or as
// separate pack we'd only need one locale per language.

require('moment/locale/es');
require('moment/locale/nl');
require('moment/locale/fr');
require('moment/locale/de');
require('moment/locale/el');
require('moment/locale/it');
require('moment/locale/ja');
require('moment/locale/ko');
require('moment/locale/pt-br');
require('moment/locale/ru');
require('moment/locale/es');
